/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as DashboardIcon } from '@assets/icons/dashboard.svg';
import { ReactComponent as HomeWorkIcon } from '@assets/icons/homework.svg';
import { ReactComponent as NotificationIcon } from '@assets/icons/notifications.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';
import { ReactComponent as LeaderboardIcon } from '@assets/icons/leaderboard.svg';

import { HOMEWORKS, LEADERBOARD, NOTIFICATIONS, SETTINGS } from './routes';

// TODO: Add dashboard later
export const STUDENT_MENU_ITEMS = [
  {
    id: 1,
    Icon: HomeWorkIcon,
    label: 'Homeworks',
    route: HOMEWORKS.HOMEWORKSLIST
  },
  {
    id: 2,
    Icon: NotificationIcon,
    label: 'Notifications',
    route: NOTIFICATIONS.NOTIFICATIONS
  },
  {
    id: 3,
    Icon: LeaderboardIcon,
    label: 'Leaderboard',
    route: LEADERBOARD.LEADERBOARD
  },
  {
    id: 4,
    Icon: SettingsIcon,
    label: 'Settings',
    route: SETTINGS.SETTINGS
  }
];

export const PARENT_MENU_ITEMS = [
  {
    id: 1,
    Icon: HomeWorkIcon,
    label: 'Homeworks',
    route: HOMEWORKS.HOMEWORKSLIST
  },
  {
    id: 2,
    Icon: NotificationIcon,
    label: 'Notifications',
    route: NOTIFICATIONS.NOTIFICATIONS
  },
  {
    id: 3,
    Icon: SettingsIcon,
    label: 'Settings',
    route: SETTINGS.SETTINGS
  }
];
