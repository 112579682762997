export type QueryParams = {
  [key: string]: string | number | boolean;
};

export const buildQueryString = (params: QueryParams): string => {
  const queryString = Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          params[key].toString()
        )}`
    )
    .join('&');

  return queryString ? `?${queryString}` : '';
};
