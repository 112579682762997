import {
  ERROR,
  HOMEWORKS,
  LEADERBOARD,
  NOTIFICATIONS,
  SETTINGS
} from '../../constants/routes';

enum Event {
  // Login Page sample events

  LOGIN_MOBILE_NUMBER_SUCCESS = 'Login mobile number success',
  LOGIN_MOBILE_NUMBER_ERROR = 'Login mobile number error',
  LOGIN_OTP = 'OTP Login Attempt',
  LOGIN_CONTACT_SUPPORT = 'Login/Application Issues',

  // Homeworks

  HOMEWORKS_SUBJECT_SELECTION = 'Subject Selected',
  HOMEWORKS_SUBMIT_ON_TIME = 'Completed on Time',
  HOMEWORKS_SUBMIT_OVERDUE = 'Completed Late',
  HOMEWORKS_SUPPLEMENTARY_QUESTION = 'Supplementary Question Level',
  ASSIGNMENT_SUMMARY = 'Assignment Summary',

  // Parent

  STUDENT_PROFILE = 'Profile Switch',
  STUDENT_ASSIGNMENT_STATUS = 'Assignment Status',
  STUDENT_ASSIGNMENT_POINTS = 'Assignment Points',

  // AI

  AI_ANSWER_EVALUATION = 'Answer Evaluation',
  AI_SUMMARY = 'Summary Generated',

  // Gamification

  MASCOT_SELECTION = 'Mascot Selected',
  MASCOT_LEVEL_UNLOCK = 'Level Unlocked',

  // Notification

  PUSH_NOTIFICATION = 'Notification Sent',

  // Delete Account

  DELETE_ACCOUNT = 'Delete Account',

  // Error

  APP_FAILURE_OR_CRASH = 'Crash Occurred'
}

export default Event;

export const PageNames = {
  [HOMEWORKS.HOMEWORKSLIST]: 'Homeworks',
  [NOTIFICATIONS.NOTIFICATIONS]: 'Notifications',
  [SETTINGS.SETTINGS]: 'Settings',

  // Student only

  [LEADERBOARD.LEADERBOARD]: 'Leaderboard',
  [HOMEWORKS.REVIEW]: 'Review',
  [HOMEWORKS.SUBMISSION]: 'Submission',

  // Parent only

  [HOMEWORKS.REVIEW_BY_STUDENT_ID]: 'ReviewByStudentId',
  [HOMEWORKS.STUDENT_REPORT]: 'Report',

  // Error

  [ERROR.ERROR]: 'Error Page',
  [ERROR.PAGE_404]: 'Error 404'
};

export const pathList = Object.keys(PageNames).map((item) => ({
  path: item
}));
