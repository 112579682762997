import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Spinner from '@components/spinner/Spinner';
import backgroundImage from '@assets/images/auth_background.svg';

const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const AuthLayout = ({ className = 'md:min-w-568 md:max-w-568' }) => (
  <div
    className="flex h-screen items-center overflow-hidden"
    style={backgroundStyle}
  >
    <section
      className="flex w-full items-center justify-center bg-transparent p-4
     lg:p-4 xl:p-10 "
    >
      <div
        className={`border-borderPrimary3 flex flex-col items-center justify-center rounded-48 bg-white p-8 pt-11 md:p-16 lg:p-90 xl:m-6 ${className}`}
      >
        <Suspense
          fallback={
            <div className="flex size-full items-center justify-center">
              <Spinner />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </section>
  </div>
);

export default AuthLayout;
