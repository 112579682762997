import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  MouseEvent,
  TouchEvent
} from 'react';
import { debounce } from '@mui/material';
import clsx from 'clsx';

import { WhiteboardHandle, WhiteboardProps } from '@types';

const Whiteboard = forwardRef<WhiteboardHandle, WhiteboardProps>(
  (props, ref) => {
    const {
      id,
      className,
      canvasClassName,
      strokeColor = 'black',
      canvasColor = 'white',
      strokeWidth = 5,
      isSaveButton = true,
      isEraserButton = false,
      isClearCanvasButton = true,
      isUndoButton = false,
      isRedoButton = false,
      buttonStyles,
      disableAllButtons = false,
      disableColorPalette = true,
      disableStrokeWidthChange = true,
      disableGridOption = true,
      setSaveCanvasData,
      canvasDisabled = false
    } = props;
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const offscreenCanvasRef = useRef<HTMLCanvasElement>(
      document.createElement('canvas')
    );
    const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [history, setHistory] = useState<string[]>([]);
    const [redoList, setRedoList] = useState<string[]>([]);
    const [lineColor, setLineColor] = useState(strokeColor);
    const [lineWidth, setLineWidth] = useState(strokeWidth);
    const [backgroundColor, setBackgroundColor] = useState(canvasColor);
    const [showGrid, setShowGrid] = useState(false);
    const [isErasing, setIsErasing] = useState(false);

    const stopEventPropagation = (
      e: MouseEvent<HTMLCanvasElement> | TouchEvent<HTMLCanvasElement>
    ) => {
      e.stopPropagation();
      e.preventDefault();
    };

    const drawGrid = (
      ctx: CanvasRenderingContext2D,
      gridWidth: number,
      gridHeight: number
    ) => {
      const gridSize = 20;
      ctx.strokeStyle = '#e0e0e0';
      for (let x = 0; x < gridWidth; x += gridSize) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, gridHeight);
        ctx.stroke();
      }
      for (let y = 0; y < gridHeight; y += gridSize) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(gridWidth, y);
        ctx.stroke();
      }
    };

    const initializeCanvas = () => {
      const ctx = ctxRef.current;
      const canvas = canvasRef.current;
      if (ctx && canvas) {
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        if (showGrid) {
          drawGrid(ctx, canvas.width, canvas.height);
        }
      }
    };

    const getCanvasCoordinates = (
      event:
        | React.MouseEvent<HTMLCanvasElement>
        | React.TouchEvent<HTMLCanvasElement>
    ) => {
      const canvas = canvasRef.current;
      if (!canvas) return { x: 0, y: 0 };

      let clientX: number;
      let clientY: number;

      if (event.type.startsWith('mouse')) {
        const mouseEvent = event as React.MouseEvent<HTMLCanvasElement>;
        clientX = mouseEvent.clientX;
        clientY = mouseEvent.clientY;
      } else {
        const touchEvent = event as React.TouchEvent<HTMLCanvasElement>;
        const { touches } = touchEvent;
        if (touches.length > 0) {
          clientX = touches[0].clientX;
          clientY = touches[0].clientY;
        } else {
          return { x: 0, y: 0 }; // No touch points available
        }
      }

      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      stopEventPropagation(event);

      return {
        x: (clientX - rect.left) * scaleX,
        y: (clientY - rect.top) * scaleY
      };
    };

    const startDrawing = (x: number, y: number) => {
      if (ctxRef.current) {
        ctxRef.current.beginPath();
        ctxRef.current.moveTo(x, y);
        setIsDrawing(true);
      }
    };

    const draw = (x: number, y: number) => {
      if (isDrawing && ctxRef.current) {
        ctxRef.current.lineTo(x, y);
        if (isErasing) {
          ctxRef.current.strokeStyle = backgroundColor; // Set the stroke style to the background color for erasing
          ctxRef.current.lineWidth = 20; // Set a larger line width for erasing
        } else {
          ctxRef.current.strokeStyle = lineColor;
          ctxRef.current.lineWidth = lineWidth;
        }
        ctxRef.current.stroke();
      }
    };

    const endDrawing = () => {
      if (isDrawing && ctxRef.current) {
        ctxRef.current.closePath();
        setIsDrawing(false);
        // Save the current state of the canvas to history
        const currentCanvasData = canvasRef.current!.toDataURL();
        setHistory((prevHistory) => [...prevHistory, currentCanvasData]);

        // Clear the redo list as a new action invalidates the redo history
        setRedoList([]);
      }
    };

    const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
      const { x, y } = getCanvasCoordinates(e);
      startDrawing(x, y);
      stopEventPropagation(e);
    };

    const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
      if (!isDrawing) return;
      const { x, y } = getCanvasCoordinates(e);
      draw(x, y);
      stopEventPropagation(e);
    };

    const handleTouchStart = (e: TouchEvent<HTMLCanvasElement>) => {
      stopEventPropagation(e);
      const { x, y } = getCanvasCoordinates(e);
      startDrawing(x, y);
    };

    const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) => {
      stopEventPropagation(e);
      if (!isDrawing) return;
      const { x, y } = getCanvasCoordinates(e);
      draw(x, y);
    };

    const restoreCanvas = (images: string[]) => {
      const ctx = ctxRef.current;
      const canvas = canvasRef.current;
      const offscreenCanvas = offscreenCanvasRef.current;
      const offscreenCtx = offscreenCanvas.getContext('2d');

      if (ctx && canvas && offscreenCtx) {
        // Set up the offscreen canvas
        offscreenCanvas.width = canvas.width;
        offscreenCanvas.height = canvas.height;
        offscreenCtx.clearRect(
          0,
          0,
          offscreenCanvas.width,
          offscreenCanvas.height
        );
        offscreenCtx.fillStyle = backgroundColor;
        offscreenCtx.fillRect(
          0,
          0,
          offscreenCanvas.width,
          offscreenCanvas.height
        );

        if (showGrid) {
          drawGrid(offscreenCtx, offscreenCanvas.width, offscreenCanvas.height);
        }
        // Draw each image from the history onto the offscreen canvas
        images.forEach((imageSrc, index) => {
          const img = new Image();
          img.src = imageSrc;
          img.onload = () => {
            offscreenCtx.drawImage(
              img,
              0,
              0,
              offscreenCanvas.width,
              offscreenCanvas.height
            );

            // After the last image is loaded, update the main canvas
            if (index === images.length - 1) {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(offscreenCanvas, 0, 0);
            }
          };
        });
      }
    };

    const handleEraserToggle = () => {
      setIsErasing(!isErasing);
    };

    const handleRedo = () => {
      if (redoList.length === 0) return;
      const redoImage = redoList[redoList.length - 1];
      const newRedoList = redoList.slice(0, -1);
      setRedoList(newRedoList);
      setHistory((prevHistory) => [...prevHistory, redoImage]);
      restoreCanvas([...history, redoImage]);
    };

    const clearCanvas = (undo = false) => {
      const ctx = ctxRef.current;
      const canvas = canvasRef.current;
      if (ctx && canvas) {
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        if (showGrid) {
          drawGrid(ctx, canvas.width, canvas.height);
        }
        setHistory([]);
        // prevents clearing by undo
        if (!undo) {
          setRedoList([]);
        }
      }
    };

    const handleUndo = () => {
      if (history.length === 0) return;
      const currentCanvasData = canvasRef.current!.toDataURL();
      setRedoList((prevRedoList) => [...prevRedoList, currentCanvasData]);
      const newHistory = history.slice(0, -1);
      setHistory(newHistory);

      // Restore canvas from the new history state
      if (newHistory.length > 0) {
        restoreCanvas(newHistory);
      } else {
        // If history is empty, clear the canvas
        clearCanvas(true);
      }
    };

    const getFile = (): Promise<File | null> => {
      const canvas = canvasRef.current;
      if (!canvas) return Promise.resolve(null);

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'canvas.png', { type: 'image/png' });
            resolve(file);
          } else {
            resolve(null);
          }
        });
      });
    };

    const saveAsImage = () => {
      getFile().then((file) => {
        if (file) {
          return file;
        }
        return {};
      });
    };

    const handleLineColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLineColor(e.target.value);
      if (ctxRef.current) {
        ctxRef.current.strokeStyle = e.target.value;
      }
    };

    const handleLineWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLineWidth(parseInt(e.target.value, 10));
      if (ctxRef.current) {
        ctxRef.current.lineWidth = parseInt(e.target.value, 10);
      }
    };

    const handleBackgroundColorChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setBackgroundColor(e.target.value);
    };

    const handleGridToggle = () => {
      setShowGrid(!showGrid);
    };

    useImperativeHandle(ref, () => ({
      undo: handleUndo,
      redo: handleRedo,
      clear: clearCanvas,
      saveAsImage,
      getFile
    }));

    const debouncedResize = debounce(() => {
      const canvas = canvasRef.current?.toDataURL() || '';
      if (canvasRef.current) {
        canvasRef.current.width = canvasRef.current.clientWidth;
        canvasRef.current.height = canvasRef.current.clientHeight;
        restoreCanvas([canvas]);
      }
    }, 100); // Adjust debounce time as necessary

    useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        ctxRef.current = canvas.getContext('2d');
        initializeCanvas();
        const resizeObserver = new ResizeObserver(debouncedResize);
        resizeObserver.observe(canvas);

        return () => {
          resizeObserver.disconnect();
        };
      }

      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundColor, showGrid, lineColor, lineWidth]);

    useEffect(() => {
      if (setSaveCanvasData) {
        if (canvasRef?.current) setSaveCanvasData(canvasRef.current);
        else setSaveCanvasData(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef]);

    return (
      <div
        id={id}
        className={clsx(
          'flex flex-col items-center',
          'touch-none', // disable touch action while to disable scroll while writing
          className
        )}
      >
        <canvas
          id={`canvas_${id}`}
          className={clsx(
            'flex-1',
            canvasDisabled
              ? 'pointer-events-none cursor-not-allowed'
              : 'cursor-crosshair',
            canvasClassName
          )}
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={endDrawing}
          onMouseLeave={endDrawing}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={endDrawing}
          onTouchCancel={endDrawing}
        />
        {disableAllButtons && (
          <div className="my-4 flex w-full select-none justify-start gap-2">
            {isUndoButton && (
              <button
                type="button"
                className={buttonStyles}
                onClick={handleUndo}
              >
                Undo
              </button>
            )}
            {isRedoButton && (
              <button
                type="button"
                className={buttonStyles}
                onClick={handleRedo}
              >
                Redo
              </button>
            )}
            {isClearCanvasButton && (
              <button
                type="button"
                className={buttonStyles}
                onClick={() => clearCanvas()}
              >
                Clear
              </button>
            )}
            {isSaveButton && (
              <button
                type="button"
                className={buttonStyles}
                onClick={saveAsImage}
              >
                Save
              </button>
            )}
            {isEraserButton && (
              <button
                type="button"
                className={buttonStyles}
                onClick={handleEraserToggle}
              >
                {isErasing ? 'Drawing' : 'Eraser'}
              </button>
            )}
            {!disableColorPalette && (
              <label className="flex items-center">
                Line Color: &nbsp;
                <input
                  type="color"
                  value={lineColor}
                  onChange={handleLineColorChange}
                />
              </label>
            )}
            {!disableStrokeWidthChange && (
              <label className="flex items-center">
                Line Width: &nbsp;
                <input
                  type="number"
                  value={lineWidth}
                  min="1"
                  max="20"
                  onChange={handleLineWidthChange}
                />
              </label>
            )}
            {!disableColorPalette && (
              <label className="flex items-center">
                Background Color: &nbsp;
                <input
                  type="color"
                  value={backgroundColor}
                  onChange={handleBackgroundColorChange}
                />
              </label>
            )}
            {!disableGridOption && (
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={showGrid}
                  onChange={handleGridToggle}
                />
                &nbsp; Show Grid
              </label>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default Whiteboard;
