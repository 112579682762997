import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse, Scalars } from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudentMascots: builder.query<HTTPResponse<any>, void>({
      query: () => ({
        url: END_POINTS.MASCOT.STUDENT_MASCOTS,
        method: 'GET'
      })
    }),
    getAllMascots: builder.query<
      HTTPResponse<any>,
      { param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.MASCOT.GET_ALL_MASCOTS}${reqBody.param}`,
        method: 'GET'
      })
    }),
    getMascotsForParent: builder.query<
      HTTPResponse<any>,
      { studentId: string }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.PARENT.STUDENT_MASCOTS(reqBody.studentId)}`,
        method: 'GET'
      })
    }),
    saveMascot: builder.mutation<HTTPResponse<any>, any>({
      query: (credentials) => ({
        url: END_POINTS.MASCOT.STUDENT_MASCOTS,
        method: 'POST',
        body: credentials
      })
    }),
    stageNotified: builder.mutation<HTTPResponse<any>, any>({
      query: (credentials) => ({
        url: END_POINTS.LEADERBOARD.STAGE_NOTIFIED,
        method: 'PATCH',
        body: credentials
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetStudentMascotsQuery,
  useLazyGetAllMascotsQuery,
  useGetStudentMascotsQuery,
  useGetAllMascotsQuery,
  useSaveMascotMutation,
  useLazyGetMascotsForParentQuery,
  useStageNotifiedMutation
} = extendedBaseQuery;
