import { Navigate, RouteObject } from 'react-router-dom';

import { ERROR, HOME, HOMEWORKS } from '@constants/routes';
import AuthRoutes from '@features/authentication/routes';
import HomeworkRoutes from '@features/homeworks/routes';
import NotificationRoutes from '@features/notification/routes';
import MainLayoutWrapper from '@contexts/MainLayoutWrapper';
import SettingsRoutes from '@features/settings/routes';
import ErrorPage from '@features/error/pages/error-page/ErrorPage';
import LeaderboardRoutes from '@features/leaderboard/routes';

const Routes: RouteObject[] = [
  AuthRoutes,
  {
    path: HOME.HOME,
    element: <Navigate to={HOMEWORKS.HOMEWORKSLIST} /> // Re-route from '/' to '/homeworks'
  },
  {
    element: <MainLayoutWrapper />, // Outlet in MainLayout will be replaced by matching element from below
    children: [
      ...HomeworkRoutes,
      ...NotificationRoutes,
      ...SettingsRoutes,
      ...LeaderboardRoutes
    ]
  },
  {
    path: ERROR.ERROR,
    element: <ErrorPage />
  },
  {
    path: '*',
    element: <Navigate to="/error/404" />
  }
];

export default Routes;
