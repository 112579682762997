import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { AUTH } from '@constants/routes';
import AuthLayout from '@layouts/auth-layout/AuthLayout';
import AccessGuard from '@contexts/AccessGuard';
import { RoleType } from '@types';

const LoginPage = lazy(() => import('./pages/login/Login'));

const MascotSelection = lazy(
  () => import('./pages/mascot-selection/MascotSelection')
);

const SubjectSelection = lazy(
  () => import('./pages/subject-selection/SubjectSelection')
);

export const AuthRoutes: RouteObject = {
  children: [
    {
      element: <AuthLayout />,
      children: [{ path: AUTH.LOGIN, element: <LoginPage /> }]
    },
    {
      element: <AuthLayout className="md:min-w-568 md:max-w-800" />,
      children: [
        {
          path: AUTH.MASCOT_SELECTION,
          element: (
            <AccessGuard role={RoleType.STUDENT}>
              <MascotSelection />
            </AccessGuard>
          )
        }
      ]
    },
    {
      element: <AuthLayout className="md:min-w-568 md:max-w-800" />,
      children: [
        {
          path: AUTH.SUBJECT_SELECTION,
          element: (
            <AccessGuard role={RoleType.STUDENT}>
              <SubjectSelection />
            </AccessGuard>
          )
        }
      ]
    }
  ]
};

export default AuthRoutes;
