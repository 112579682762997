import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, SidebarItem } from '@components';
import { STUDENT_MENU_ITEMS, PARENT_MENU_ITEMS } from '@constants/sideMenu';
import Logo from '@assets/images/logo-black.svg';
import LogoLetter from '@assets/images/logo-letter-black.svg';
import { AUTH } from '@constants/routes';
import { clearAllLocalStorageItems } from '@utils/storage';
import { RoleType, SideNavProps } from '@types';
import { resetUserState } from '@features/authentication/userSlice';

const SideNavBar: FC<SideNavProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { role } = useSelector((state: any) => state.user);
  const isStudent = role === RoleType.STUDENT;
  const isParent = role === RoleType.PARENT;

  const getMenuItems = () => {
    if (isStudent && !isParent) {
      return STUDENT_MENU_ITEMS;
    }
    if (isParent && !isStudent) {
      return PARENT_MENU_ITEMS;
    }
    return PARENT_MENU_ITEMS;
  };

  const [isSidebarOpen, setSideBarOpen] = useState<boolean>(false);

  const openSidebar = () => {
    setSideBarOpen(true);
  };

  const closeSidebar = () => {
    setSideBarOpen(false);
  };

  const onClickLogout = () => {
    clearAllLocalStorageItems();
    dispatch(resetUserState());
    navigate(AUTH.LOGIN);
  };

  return (
    <div
      onMouseEnter={openSidebar}
      onMouseLeave={closeSidebar}
      className={`absolute left-0 top-0 z-50 flex h-screen flex-col justify-between bg-theme shadow-sm transition-all duration-300 ease-in-out ${
        isSidebarOpen ? 'w-270' : 'w-72'
      }`}
    >
      <div>
        <div className="h-12 px-6 pb-6 pt-8">
          <img
            src={Logo}
            alt="Tara Logo"
            className={`${isSidebarOpen ? '' : 'hidden'}`}
            width={66}
            height={45}
          />
          <img
            src={LogoLetter}
            alt="Tara"
            className={`${
              isSidebarOpen ? 'hidden' : 'ml-1 flex items-center justify-center'
            }`}
            width={18}
            height={23}
          />
        </div>
        <div className="mt-6 flex flex-col gap-3 py-3">
          {getMenuItems().map((item: any) => {
            const { route } = item;
            const isCurrentRoute = location.pathname.startsWith(route);
            const path = route;

            return (
              <SidebarItem
                key={item.label}
                item={item}
                path={path}
                isCurrentRoute={isCurrentRoute}
                isSidebarOpen={isSidebarOpen}
              />
            );
          })}
        </div>
      </div>
      <div
        className="mx-4 mb-4 flex cursor-pointer items-center gap-3 rounded-lg bg-errorLite p-3"
        onClick={onClickLogout}
        role="presentation"
      >
        <Icon className="size-6 text-error700" name="logout" />
        <div className="truncate text-center text-base font-semibold text-error700">
          Logout
        </div>
      </div>
    </div>
  );
};
export default SideNavBar;
