import React, { FC } from 'react';
import clsx from 'clsx';

import { NotificationCardProps } from '@types';
import { formatISODate } from '@utils/date';

import Avatar from '../avatar/Avatar';
import { IconButton } from '../button/Button';
import Icon from '../icon/Icon';
import Chip from '../chip/Chip';

const NotificationCard: FC<NotificationCardProps> = (props) => {
  const { handleCardClick, notification, onDelete } = props;
  const isUnread = !notification?.isRead || false;

  const handleDelete = (e: any) => {
    onDelete?.(notification);
    e.stopPropagation();
  };

  return (
    <div
      onClick={handleCardClick}
      role="presentation"
      className={`${
        isUnread ? 'border-primary5' : 'border-primary1'
      } mt-4 cursor-pointer rounded-3xl border p-4 tablet:py-6 desktop:py-6`}
    >
      <div className="flex grow items-center gap-4 mobile:items-start tablet:flex-row desktop:flex-row">
        <div className="flex items-center gap-4">
          <span
            className={clsx(
              'size-2.5 rounded-full bg-primary5',
              isUnread ? 'opacity-100' : 'opacity-0'
            )}
          />

          <Avatar
            name={notification?.additionalData?.userName || 'Tara'}
            className="size-9 min-h-6 min-w-6 rounded-lg text-sm  text-white lg:size-12 tablet:size-12 tablet:min-w-12 desktop:min-w-12"
          />
        </div>
        <div className="flex w-0 grow items-center gap-4 mobile:flex-col mobile:items-start">
          <div className="flex w-full grow items-center">
            <div className="w-0 grow">
              <p className="truncate text-base font-semibold leading-6 text-grey10">
                {notification?.message?.header}
              </p>
              <p className="truncate text-base leading-6 text-grey8 mobile:text-sm">
                {notification?.message?.body}
              </p>
            </div>

            {onDelete && (
              <IconButton
                className="ms-auto hidden flex-none !p-2 text-grey mobile:block"
                iconName="trash"
                handleClick={handleDelete}
                variant="transparent"
              />
            )}
          </div>
          <div className="flex items-center justify-between mobile:w-full tablet:w-auto desktop:w-auto">
            {notification?.additionalData?.type && (
              <Chip
                isCloseIconRequired={false}
                label={notification?.additionalData?.type}
                className="w-28 min-w-28 justify-center bg-yellow75 px-1.5 text-yellow700 mobile:min-w-fit"
                isTruncate={false}
              />
            )}
            <div className="me-2 ms-6 flex min-w-20 items-start justify-between lg:min-w-20 mobile:me-0 mobile:ms-1 mobile:w-full mobile:justify-evenly mobile:gap-1 tablet:flex-col desktop:flex-col">
              <p className="flex gap-1">
                <Icon name="calendar" stroke="#8F95B2" />
                <span className="flex items-center whitespace-nowrap mobile:text-xs">
                  {formatISODate({
                    date: notification?.createdAt,
                    format: 'MMM DD',
                    dateSuffix: true
                  })}
                </span>
              </p>
              <p className="flex items-center gap-1 desktop:items-end">
                <Icon name="clock" />
                <span className="flex items-center whitespace-nowrap mobile:text-xs">
                  {formatISODate({
                    date: notification?.createdAt,
                    format: 'hh:mm'
                  })}
                </span>
              </p>
            </div>
            {onDelete && (
              <IconButton
                className="!p-2 text-grey duration-200 mobile:hidden"
                iconName="trash"
                handleClick={handleDelete}
                variant="transparent"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
