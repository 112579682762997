import math from '@assets/subjects/math.svg';
import english from '@assets/subjects/english.svg';
import science from '@assets/subjects/science.svg';

// eslint-disable-next-line import/prefer-default-export
export const getSubjectIcon = (subject: string) => {
  switch (subject.toLowerCase()) {
    case 'maths':
      return math;
    case 'english':
      return english;
    case 'science':
      return science;
    default:
      return math;
  }
};

interface GetImageUrlProps {
  imageList: any[];
  type: string;
  isThumbnail?: boolean;
}
export const getImageUrl = ({
  imageList,
  type,
  isThumbnail = false
}: GetImageUrlProps) => {
  const image = imageList?.find((img: any) => img.emotion === type);
  if (image) {
    return isThumbnail ? image.imageThumbnailUrl : image.imageUrl;
  }
  return '';
};
