import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import AccessGuard from '@contexts/AccessGuard';
import { LEADERBOARD } from '@constants/routes';
import { RoleType } from '@types';

const Leaderboard = lazy(
  () => import('@features/leaderboard/pages/leaderboard/Leaderboard')
);

export const LeaderboardRoutes: RouteObject[] = [
  {
    path: LEADERBOARD.LEADERBOARD,
    element: (
      <AccessGuard role={RoleType.STUDENT}>
        <Leaderboard />
      </AccessGuard>
    )
  }
];

export default LeaderboardRoutes;
